/**
 * Debounce and throttle helper function's
 * 
 * https://remysharp.com/2010/07/21/throttling-function-calls
 * 
 * Examples: $('input.username').keypress(debounce(function (event) { // do the
 * Ajax request }, 250));
 * 
 * $('body').on('mousemove', throttle(function (event) { console.log('tick'); },
 * 1000));
 * 
 */

function debounce(fn, delay) {
    var timer = null;
    return function() {
        var context = this, args = arguments;
        clearTimeout(timer);
        timer = setTimeout(function() {
            fn.apply(context, args);
        }, delay);
    };
}

function throttle(fn, threshhold, scope) {
    threshhold || (threshhold = 250);
    var last, deferTimer;
    return function() {
        var context = scope || this;
        var now = +new Date, args = arguments;
        if (last && now < last + threshhold) {
            // hold on to it
            clearTimeout(deferTimer);
            deferTimer = setTimeout(function() {
                last = now;
                fn.apply(context, args);
            }, threshhold);
        } else {
            last = now;
            fn.apply(context, args);
        }
    };
}
